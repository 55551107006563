import React from 'react';
// import { Route } from 'react-router';
import Seo from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import Team from 'sections/team';
import Advisory from 'sections/advisory';
import About from 'sections/about';
import Contact from 'sections/contact';
import Gitcoin from 'sections/gitcoin'

import WebsiteData from '../../data/WebsiteData';

export default function HomePage() {
  return (
    <Layout>
      <Seo
        title={WebsiteData['SEO']['title']}
        description={WebsiteData['SEO']['description']}
      />
      <Banner />
      <About />
      <Team />
      {/* <Advisory /> */}
        {/* <Gitcoin /> */}
        <Contact />
    </Layout>
  );
}
