import React from 'react';
import { Container, Box } from 'theme-ui';
import SectionHeading from '../components/section-heading';

import WebsiteData from '../../data/WebsiteData';

const text = (<p>
    {WebsiteData['About']['text1']} 
    <a 
    href={WebsiteData['About']['SystemsBioGroup_Link']}
    target="_blank"
    >{"Systems biology of Aging academic group"}</a>
    {WebsiteData['About']['text2']}
    </p>
)

const Description = () => {
    return (
        <section id="about">
            <Container
                sx={{
                    textAlign: "justify",
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'center',
                    // justifyContent: "center",
                    marginTop: '10px',
                    paddingTop: '70px',
                }}
            >
                <Box sx={{
                    maxWidth: 600,
                }}
                >
                    <SectionHeading
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                        }}
                        title={WebsiteData['About']['title']}
                        description={text}
                    />
                </Box>
            </Container>
        </section>
    )
}

const styles = {
    section: {
        pt: [6, null, null, null, 10, 14],
        pb: [6, null, null, 8, 10, 11],
    },
    grid: {
        display: ['flex', null, null, 'grid'],
        alignItems: 'center',
        gap: [null, null, null, 6, 14],
        flexDirection: ['column-reverse', null, null, 'unset'],
        gridTemplateColumns: [null, null, null, 'repeat(2, 1fr)', '1fr 470px'],
    },
    heading: {
        textAlign: ['center', null, null, 'left'],
        h3: {
            fontSize: [3, null, null, 8, 11],
            lineHeight: 1.53,
        },
    },
    illustration: {
        mt: [8, null, null, 0],
    },
};

export default Description;