/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import Person from 'components/cards/person';


import WebsiteData from "../../data/WebsiteData";

const data = WebsiteData["Advisory"]["data"];

const Advisory = () => {
  return (
    <section sx={styles.section} id="advisory">
      <Container>
        <SectionHeading
          sx={styles.heading}
          title={WebsiteData['Advisory']['section_title']}
          description={WebsiteData['Advisory']['section_description']}
        />
        <Box sx={styles.features}>
          {data?.map((item) => (
            <Person key={item.id} className="feature-item" data={item} />
          ))}
        </Box>
      </Container>
    </section>
  );
};

export default Advisory;

const styles = {
  section: {
    pt: [12],
    pb: [6, null, null, 8, 15],
    marginTop: "70px",
  },
  heading: {
    marginBottom: [40, 50, 60, 80],
    maxWidth: ['none', null, null, 565, null, 'none'],
  },
  features: {
    gap: [6, null, null, '90px 40px'],
    display: ['grid'],
    maxWidth: 1175,
    mx: 'auto',
    justifyContent: ['center', null, null, 'unset'],
    gridTemplateColumns: [
      'repeat(1, 300px)',
      null,
      null,
      'repeat(2, 1fr)',
      'repeat(3, 1fr)',
    ],
  },
};
